export const services = new Map([
    ["ee3480a8232e72e54071cd185388c691f829fa2670e5e6a6f966385908462cb8","01. Register/Get Plates for My Vehicle"],
    ["13cd38b2175347f38fe21325ad67e1e4ec04f36e89e92804462184544ace9b2f","02. Upgrade to a Real ID or EDL (with or without renewal)"],
    ["4ec745aaba5800ddb8fea03c823118afe3a65f4635f12e8ec69b9df5d10a0eec","03. Pay Cash to Renew Your Photo Document (No Upgrade/No Photo)"],
    ["bed896835d46b0a806fc80571852b4e1233a1f2fb48531b3a4a8cd220d6b1436","04. Get a NY Non-Driver ID"],
    ["c781ba0818242f9719819f6b96bbfd10d199cdbc68b3062a462361deb8284bc3","05. Renew Your Commercial Driver License"],
    ["1051c0c0a05f68662fc92b4041a3febafe5c97f1a08cfa8ca03a37562b0b4987","06. Take a Commercial Driver License Permit or Endorsement Test"],
    ["2cac88e280dfb5f69ecf53ace1a0c00e4d43ba8d14c55a99ee5cb52e824b7389","07. Exchange Your Out of State License"],
    ["8269e653b56de4a114b84559eea58c9fcb707c9846ba447c8bb01480322bb06f","08. Restore a Revoked/Suspended License"],
    ["b2bbf5c982b9aa88b4427369f1328c59b53fdd19eca9e082947bc2c0038ca3d1","09. Apply for a Restricted/Conditional License"],
    ["ef2ce56f941fe9daafa4b57b5dff5e1295f20dfbeb9763e5b54539909be67e8b","10. Change Information or Class on your Photo Document (Non-Renewal)"],
    ["06b1dc258a49ba1f54d2dca9133fd4e24ff6c2890d044f8d9d49a2060b441ac8","11. Change Name on Photo Document"],
    ["10226f4de0f460aa67bb735db97f9eb434b8ac2a144e40a20ff1e1848ffbeae7","12. Take a Car or Motorcycle Permit Test"],
]);

export function getServiceName(serviceId: string):string {
    return services.get(serviceId)!;
}

export function setServiceName(serviceId: string, name: string) {
    return services.set(serviceId, name)!;
}