import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import Dates from "./components/Dates";
import { branches } from "./config/branches";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { services } from "./config/services";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 400,
    width: 300,
  },
  control: {
    padding: theme.spacing(1),
  },
}));

function App() {
  let servicesList = [];
  let servicesRoutes = [];

  services.set(
    "b420ddedeb852f349d243f2fef5ef8de8b2548ed594feb8168dc8618776f2a14",
    "Online Permit"
  );
  for (let [key, value] of services) {
    let path = "/" + key;
    servicesList.push(
      <li key={key}>
        <Link to={path} key={key}>
          {value}
        </Link>
      </li>
    );

    servicesRoutes.push(
      <Route path={path} key={key}>
        <Service>{key}</Service>
      </Route>
    );
  }

  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {servicesList}
          </ul>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          {servicesRoutes}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return <p>Home</p>;
}

function Service(props: any) {
  const classes = useStyles();

  let b = [];
  for (let [key, value] of branches) {
    b.push(
      <Grid key={value} item>
        <Paper className={classes.paper}>
          <Dates key={value} branch={key} service={props.children} />
        </Paper>
      </Grid>
    );
  }

  return (
    <div className="App">
      <Grid container justify="center" spacing={3}>
        {b}
      </Grid>
    </div>
  );
}

export default App;
